.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}:root{
  --position: fixed;
  --buttonWidth: 120px;
  --buttonHeight: 30px;
  --buttonRadius: 40px;
}
.parent{
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000;
}
.text-editor{
  width:  40%;
  height: 90%;
  position: absolute;
  left: 0px;
  padding: 20px;
  border:  1px #000 solid;
  font-size: 1em;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.45em;
  padding-top: 35px;
}
.text-editor:focus{
  outline: none;
  border:  1px #000  solid;
}
.preview{
  width: 52%;
  height: 90%;
  left: 45%;
  border: 1px #000 solid;
  padding: 20px;
  position: absolute;
  top: 0;
  background-color: #fff;
}
.controls{
  width: 100%;
  padding: 10px 10px;
  position: var(--position);
  bottom: 0;
  background-color: #000;
}
.submit{
  width: var(--buttonWidth);
  height: var(--buttonHeight);
  border-radius: var(--buttonRadius);
  background-color: #ffe200;
  border: 0px;
  float: right;
  margin-right: 30px;
  transition: ease-in-out 5s;
}

button:focus, input:focus, button:hover, input:hover{
  outline: none;
  opacity: .7;
  transform: rotateY(20deg);
  transition: ease-in-out 5s;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
select{
  width: var(--buttonWidth*1.15);
  height: var(--buttonHeight);
  border-radius: var(--buttonRadius);
  background-color: #ffe200;
  border: 0px;
  float: right;
  margin-right: 20px;
  transition: ease-in-out 5s;
  padding: 5px 10px;
  position: fixed;
  right: 130px;
}
select:focus{
  outline: none;
}